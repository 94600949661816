<template>
  <div
    class="zoomable-container"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <!-- 这里放置需要缩放的内容 -->
    <div class="zoomable-content">
      <!-- 例如，一个图片 -->
      <div class="aaaaa" :style="{ transform: `scale(${scale})` }">
        {{ scale }}
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/../config/index";

export default {
  data() {
    return {
      imageSrc: "path/to/your/image.jpg", // 图片源地址
      scale: 1, // 缩放比例
      startX: 0, // 初始触摸点的X坐标
      startY: 0, // 初始触摸点的Y坐标
      prevDistance: 0, // 上一次触摸点之间的距离
      pinching: false, // 是否正在缩放
      touchIds: [], // 记录触摸点的ID
    };
  },
  created() {
    console.log(config);
  },
  methods: {},
};
</script>

<style scoped>
.aaaaa {
  background-color: aquamarine;
  width: 100%;
  height: 100%;
}

.zoomable-content {
  width: 90vw;
  height: 90vh;
}

/* 根据需要添加其他样式 */
</style>
